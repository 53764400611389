.item-name-active::before {
    content: "";
    background-color: rgb(0, 163, 92);
    left: 0px;
    top: 6px;
    bottom: 6px;
    width: 4px;
    height: 15px;
    border-radius: 0px 6px 6px 0px;
    transition: transform 150ms ease-in-out 0s;
    transform: scaleY(1);
}

.li {
    color: #332e38;
    text-transform: capitalize;
    display: flex;
    align-items: center;
    font-size: 13px;
    cursor: pointer;
    padding: 12px 24px;
    transition: all .15s ease-in;
    font-weight: 600;
}

.li:hover {
    background: #eee;
}