.picker {
    padding: 10px;
    position: relative;
}

.picker:hover {
    background-color: azure;
    cursor: pointer;
}

.box-calendar {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
}
.daterangepicker { 
    
}
.footer-date{ 
    background-color: white;
    border-top: solid 1px #eff2f7;
    }

.apply-cancel:hover {
    background-color: whitesmoke;
    cursor: pointer;
    border-radius: 5px;
}