  .outline:hover {
      border: 1px solid blue !important;
      color: blue
  }

  .item-devtype {
      cursor: pointer;
  }

  .item-devtype:hover {
      border: 1px solid blue !important;
  }

  .span-devtype {
      font-size: 14px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: #272e3b;
      font-weight: 500;
  }

  .card-selected:before {
      border-color: transparent #005cff #005cff transparent;
      border-style: solid;
      border-width: 12px;
      bottom: 0;
      content: "";
      display: block;
      height: 0;
      position: absolute;
      right: 0;
      width: 0;
      border-bottom-right-radius: 6px
  }

  .card-selected:after {
      bottom: -2px;
      color: #fff;
      content: "\eed6";
      font-family: IcoFont, sans-serif;
      font-size: 12px;
      position: absolute;
      right: 1px;
  }

  .ant-segmented-item {
      margin-bottom: 0;
      width: calc(100% / 2);
  }

  .ant-segmented-group {
      display: flex;
      flex-wrap: wrap;
  }

  .activeSegment::after {
      border-left: 8px solid #fff;
      right: -8px;
      border-left-color: #ecf3ff;
  }

  .ant-collapse-header-text {
      width: 100%;
      padding-right: 20px;
  }

  .bg-color-aliceblue {
      background-color: aliceblue;
      cursor: pointer;
  }

  .bg-color-custom {
      background-color: #e6f4ff;
    cursor: pointer;
    }